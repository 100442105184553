import gccoffee from './gccoffee.png';
import React, { useState } from 'react';
import './App.css';
// import FootballThemeImage from './assets/football-theme.jpg'; // Make sure to include a public domain football image in the assets folder

function App() {
  const [userInput, setUserInput] = useState('');
  const [apiResponse, setApiResponse] = useState('');

  // Handler for submitting the user input to the AWS REST API
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Example: POST request to AWS REST API with Axios or Fetch
    // Update setApiResponse with the response from the API
    console.log('Submit the input to an API:', userInput);
    // Dummy response handling
    setApiResponse('Received response from API');
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={gccoffee} className="App-logo" alt="logo" />
        <h1>Under Construction</h1>
      </header>
      <main>
        <form onSubmit={handleSubmit}>
          <label htmlFor="userInput">Enter Your Query:</label>
          <input
            id="userInput"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            required
          />
          <button type="submit">Submit</button>
        </form>
        {apiResponse && <p>API Response: {apiResponse}</p>}
      </main>
    </div>
  );
}

export default App;
